import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  COLORS,
  customLabel,
  DescriptiveStatistics,
  DisplayOnEmpty,
  DisplayOnError,
} from './Common';

export function EmailsSent({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    let groupedBy = data.reduce((acc, i) => {
      let key = i[by];
      acc[key] =
        (acc[key] || 0) +
        Number(i.emails.flatMap((email) => email.recipients.length));
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Emails: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Emails'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function RulesPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Rules: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Rules'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function NudgeStatistics({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  let dataToPlot = [
    {
      name: 'Active',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Unsubscribe',
      count1: 0,
      count2: 0,
      name1: 'Allowed',
      name2: 'Disallowed',
    },
    {
      name: 'Date to Target',
      count1: 0,
      count2: 0,
      name1: 'Due',
      name2: 'Late',
    },
    {
      name: 'Assignment to Target',
      count1: 0,
      count2: 0,
      count3: 0,
      name1: 'Unsubmitted',
      name2: 'Threshold',
      name3: 'All',
    },
  ];

  for (const d of data) {
    d.active ? (dataToPlot[0].count1 += 1) : (dataToPlot[0].count2 += 1);
    d.allow_unsubscribe
      ? (dataToPlot[1].count1 += 1)
      : (dataToPlot[1].count2 += 1);
    d.date_to_target === 'due'
      ? (dataToPlot[2].count1 += 1)
      : (dataToPlot[2].count2 += 1);
    if (d.student_to_target === 'unsubmitted') dataToPlot[3].count1 += 1;
    else if (d.student_to_target === 'threshold') dataToPlot[3].count2 += 1;
    else if (d.student_to_target === 'all') dataToPlot[3].count3 += 1;
  }

  return (
    <ResponsiveContainer>
      <BarChart
        layout='vertical'
        data={dataToPlot}
        margin={{ left: 20, right: 20, bottom: 10 }}
      >
        <CartesianGrid />
        <XAxis
          type='number'
          domain={[0, data.length]}
          label={{
            value: 'Num Graders',
            position: 'insideBottom',
            offset: -5,
          }}
        />
        <YAxis dataKey='name' type='category' tick={{ fontSize: '12' }} />
        <Bar
          dataKey='count1'
          stackId='a'
          fill={COLORS[0]}
          maxBarSize={100}
          label={(props) =>
            customLabel({ ...props, key: '1', data: dataToPlot })
          }
        />
        <Bar
          dataKey='count2'
          stackId='a'
          fill={COLORS[1]}
          maxBarSize={100}
          label={(props) =>
            customLabel({ ...props, key: '2', data: dataToPlot })
          }
        />
        <Bar
          dataKey='count3'
          stackId='a'
          fill={COLORS[2]}
          maxBarSize={100}
          label={(props) =>
            customLabel({ ...props, key: '3', data: dataToPlot })
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export function GradeDistributionSpecificRule({ data, scores }) {
  if (
    data === null ||
    data.length < 1 ||
    scores === null ||
    scores.length < 1
  ) {
    return <DisplayOnEmpty />;
  }
  try {
    const allGrades = scores
      .map((x) => Number(x['score']))
      .filter((x) => !isNaN(x))
      .sort((a, b) => a - b);
    const binSize = 10;
    const maxGrade = 100;
    let bins = Array.from({ length: maxGrade / binSize }, (_, i) => ({
      xAxisLabel: `${i * binSize}'s`,
      tooltipLabel: `${i * binSize}–${i * binSize + binSize - 1}`,
      count: 0,
    }));
    const negativesBin = {
      xAxisLabel: `<${0}`,
      tooltipLabel: `<${0}`,
      count: 0,
    };
    const hundredsBin = {
      xAxisLabel: `${100}+`,
      tooltipLabel: `${100}+`,
      count: 0,
    };
    allGrades.forEach((grade) => {
      if (grade < 0) {
        negativesBin.count++;
      } else if (grade >= 100) {
        hundredsBin.count++;
      } else {
        const binIndex = Math.floor(grade / bins.length);
        bins[binIndex].count++;
      }
    });
    let allBins = [...bins];
    if (hundredsBin.count > 0) allBins = [...allBins, hundredsBin];
    if (negativesBin.count > 0) allBins = [negativesBin, ...allBins];
    const dataToPlot = allBins.map((bin) => ({
      XAxisLabel: bin.xAxisLabel,
      TooltipLabel: bin.tooltipLabel,
      Count: bin.count,
    }));

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'XAxisLabel'}
            label={{
              value: 'Grade Range',
              position: 'insideBottom',
              offset: -5,
            }}
            interval={0}
          />
          <YAxis label={{ value: 'Count', angle: -90, dx: -15 }} />
          <Tooltip
            formatter={(value, name, props) => {
              const fullRange = props.payload.TooltipLabel;
              return [`Count: ${value}`, `Range: ${fullRange}`];
            }}
          />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
          <Legend
            content={<DescriptiveStatistics sortedData={allGrades} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnEmpty />;
  }
}

export function AverageGradeByRule({ data, scores }) {
  if (data === null || scores === null || scores.length < 1) {
    return <DisplayOnEmpty />;
  }
  try {
    const dataToPlot = data
      .filter((rule) => !rule?.is_deleted)
      .map((rule, index) => {
        const scoresInRule = scores
          .filter((score) => rule['students_to_include'].includes(score['id']))
          .map((score) => score['score'])
          .filter((score) => !isNaN(score));
        const avgScore =
          scoresInRule.reduce((acc, i) => acc + i, 0) / scoresInRule.length ||
          0;
        return {
          name: rule['name'],
          avgGrade: avgScore.toFixed(2),
          fill: COLORS[index % COLORS.length],
        };
      });
    if (dataToPlot.length < 1) return <DisplayOnEmpty />;
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey='name'
            label={{
              value: 'Rule Name',
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Average Grade', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey='avgGrade' maxBarSize={100}>
            {dataToPlot.map((entry, index) => (
              <Cell key={index} fill={entry.fill} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}
