import { memo, useEffect, useRef, useState } from 'react';
import { Col, InputGroup, ListGroup } from 'react-bootstrap';
import { getNudgeStats } from '../../api/nudge_api.js';
import * as cp from '../plots/Common';
import * as np from '../plots/NudgePlots';
import PlotsContainer from '../plots/PlotsContainer';
import * as wrapper from '../plots/Wrapper.js';
import SearchSelect from '../reusable/SearchSelect.js';

function Statistics({ courseId, scores }) {
  const [data, setData] = useState(null);
  const [currData, setCurrData] = useState([]);
  const [ruleOptions, setRuleOptions] = useState(null);
  const [currScores, setCurrScores] = useState(null);
  const [currRuleState, setCurrRuleState] = useState(null);
  const currRule = useRef(wrapper.defaultCurrVal);

  useEffect(() => {
    getNudgeStats(courseId).then((res) => {
      setData(res);
      setCurrData(res);
      setRuleOptions(wrapper.getUniqueItemsAsObj('name', 'name', res));
    });
    setCurrScores(scores);
  }, []);

  useEffect(() => {
    if (
      scores === null ||
      currRuleState === null ||
      currRuleState.name === ''
    ) {
      setCurrScores(scores);
    } else {
      setCurrScores(filterScores());
    }
  }, [currRuleState]);

  function getEmailRecipients(data, unique) {
    const recipients = data
      .flatMap((x) => x.emails)
      .flatMap((email) => email.recipients);
    if (unique) return new Set(recipients).size;
    else return recipients.length;
  }

  function filterByRule(val) {
    let filteredData = data;
    if (val.id !== null) {
      filteredData = data.filter((x) => x['name'] == val.id);
    }
    setCurrData(filteredData);
    setCurrRuleState(val);
    currRule.current = val;
  }

  function filterScores() {
    if (
      data === null ||
      data.length < 1 ||
      scores === null ||
      scores.length < 1 ||
      currRuleState.name === '' ||
      currRuleState === null
    ) {
      return scores;
    }
    const studentsIncluded = data.find((x) => {
      return x['name'] === currRuleState.name;
    })['students_to_include'];
    const filteredScores = scores.filter((score) => {
      return studentsIncluded.includes(score['id']);
    });
    return filteredScores;
  }

  function FilterDashboardHelper() {
    return (
      <Col>
        <InputGroup size='sm' className='mb-1 flex-nowrap'>
          <InputGroup.Text style={{ width: '100px' }}>Rule</InputGroup.Text>
          <SearchSelect
            idNameRef={currRule}
            items={ruleOptions}
            onSelect={filterByRule}
            setToNull={false}
            placeholder={'ALL'}
          />
        </InputGroup>
      </Col>
    );
  }

  function getTotalStats(data) {
    return [
      <wrapper.TotalStats
        key='0'
        title={'Total Rules'}
        subtitle={'that have been created'}
        stat={data.length}
      />,
      <wrapper.TotalStats
        key='3'
        title={'Total Students'}
        subtitle={'impacted by Nudge'}
        stat={getEmailRecipients(data, true)}
      />,
      <wrapper.TotalStats
        key='4'
        title={'Total Emails'}
        subtitle={'sent by Nudge'}
        stat={getEmailRecipients(data, false)}
      />,
    ];
  }

  function GeneralStatisticsHelper() {
    const stats = getTotalStats(data);
    return <ListGroup variant='flush'>{stats.map((x) => x)}</ListGroup>;
  }

  function getHeader() {
    if (currRule.current.id !== null)
      return `Stats for ${currRule.current.name}`;
    return `Filtered Stats`;
  }

  function getBody() {
    let arr = getTotalStats(currData).slice(1);
    return <>{arr.map((x) => x)}</>;
  }

  function plotsData() {
    const getEmails = () => {
      let emails = [];
      for (const nudge of currData) {
        emails.push(...(nudge?.emails || []));
      }
      return emails;
    };

    return [
      {
        title: 'Nudge Statistics',
        element: <np.NudgeStatistics data={currData} />,
        display: true,
      },
      {
        title: 'Emails By Hour',
        element: <cp.PlotByHour _key={'timestamp'} data={getEmails()} />,
        display: true,
      },
      {
        title: 'Emails By Day',
        element: <cp.PlotByDay _key={'timestamp'} data={getEmails()} />,
        display: true,
      },
      {
        title: 'Emails By Date',
        element: <cp.PlotByDate _key={'timestamp'} data={getEmails()} />,
        display: true,
      },
      {
        title: 'Emails Per Rule',
        element: <np.EmailsSent data={currData} by={'name'} />,
        display: currRule.current.name === '',
      },
      {
        title: 'Grade Distribution',
        element: (
          <np.GradeDistributionSpecificRule
            data={currData}
            scores={currScores}
          />
        ),
        display: currRule.current.name !== '',
      },
      {
        title: 'Average Grade By Rule',
        element: <np.AverageGradeByRule data={data} scores={scores} />,
        display: currRule.current.name === '',
      },
    ];
  }

  return (
    <wrapper.Wrapper ifNull={data === null}>
      <wrapper.FilterDashboard child={<FilterDashboardHelper />} />
      <wrapper.GeneralStatistics child={<GeneralStatisticsHelper />} />
      <wrapper.FilteredStatistics
        showDef={currRule.current.name}
        getHead={getHeader}
        getBody={getBody}
      />
      <PlotsContainer data={plotsData()} />
    </wrapper.Wrapper>
  );
}

export default memo(Statistics);
