import { memo, useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { DisplayOnError } from './Common';

function PlotsContainer({ data, fullScreen }) {
  const [relData, setRelData] = useState(null);
  const [display, setDisplay] = useState(0);
  const [lastDisplayName, setLastDisplayName] = useState(null);

  useEffect(() => {
    const displayedData = data.filter((x) => x.display);
    setRelData(displayedData);
    if (lastDisplayName !== null) {
      const lastDisplayIndex = displayedData.findIndex((x) => {
        return x.display && x.title === lastDisplayName;
      });
      if (lastDisplayIndex !== -1) {
        setDisplay(lastDisplayIndex);
        return;
      }
    }
    setDisplay(0);
  }, [data]);

  useEffect(() => {
    if (relData === null) {
      setLastDisplayName(null);
    } else {
      setLastDisplayName(relData[display].title);
    }
  }, [display, relData]);

  return (
    <>
      {relData && relData.length > 0 && (
        <>
          <div className='border-bottom' style={{ height: '50px' }}>
            <Col
              xs={12}
              lg={6}
              className='px-3 py-2 d-flex align-items-center small'
            >
              <InputGroup size='sm'>
                <InputGroup.Text>Select Plot To View</InputGroup.Text>
                <Form.Select
                  value={display}
                  onChange={(e) => {
                    setDisplay(e.target.value);
                  }}
                >
                  {relData.map((x, i) => (
                    <option value={i} key={i}>
                      {x.title}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
          </div>
          <div
            className='px-3'
            style={{ minHeight: '300px', height: fullScreen ? '100%' : '80%' }}
          >
            {relData[display].element || <DisplayOnError />}
          </div>
        </>
      )}
    </>
  );
}

export default memo(PlotsContainer);
