import { memo, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getGradeSummary, getTeachers } from '../../api/grade_api';
import AdvancedTable from '../reusable/AdvancedTable';
import Error from '../reusable/Error';
import LoadingSpinner from '../reusable/LoadingSpinner';
import AssignmentGrade from './AssignmentGrade';
import DashboardOptions from './DashboardOptions';
import Statistics from './Statistics';

function Grade({ courseId, assignments, students, courseCode }) {
  const [configStats, setConfigStats] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [viewChange, setViewChange] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('grade')) setViewChange((prev) => !prev);
  }, [location]);

  function getName(id) {
    const result = assignments.find((item) => item.id === id);
    if (result) {
      return result.name;
    }
    return null;
  }

  useEffect(() => {
    if (assignments === null) return;

    getGradeSummary(courseId).then((res) => {
      const summary = res.data;
      const arrIds = summary.map((x) => x.id);
      for (const assign of assignments) {
        if (!arrIds.includes(assign.id)) {
          summary.push({
            id: assign.id,
            active: null,
            logs_count: null,
            last_log_date: null,
            last_log_status: null,
          });
        }
      }
      summary.forEach((x) => {
        x.name = getName(x.id);
        x.last_log_date =
          x.last_log_date === null ? '—' : new Date(x.last_log_date);
        x.logs_count = x.logs_count === null ? '—' : x.logs_count;
      });
      summary.sort((a, b) => {
        if (a.active === null && b.active !== null) return 1;
        if (a.active !== null && b.active === null) return -1;
        return a.name.localeCompare(b.name);
      });
      setConfigStats(summary);
    });

    getTeachers(courseId).then((res) => {
      setTeachers(res.data);
    });
  }, [courseId, assignments, viewChange]);

  return configStats ? (
    <Routes>
      <Route
        path=''
        element={
          <Dashboard
            configStats={configStats}
            courseId={courseId}
            setViewChange={setViewChange}
          />
        }
      />
      {configStats.map((stat) => (
        <Route
          key={stat.id}
          path={`${stat.id}`}
          element={
            <AssignmentGrade
              exists={stat.active !== null}
              assignmentID={stat.id}
              assignmentName={getName(stat.id)}
              courseId={courseId}
              students={students}
              courseCode={courseCode}
              setViewChange={setViewChange}
              teachers={teachers}
            />
          }
        />
      ))}
      <Route
        path='/statistics'
        element={<Statistics courseId={courseId} assignments={assignments} />}
      />
      <Route
        path='/*'
        element={<Error error={404} message={'Page was not found!'} />}
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Graders...' />
  );
}

function Dashboard({ configStats, courseId, setViewChange }) {
  const navigate = useNavigate();
  const COLUMNS = [
    {
      id: 'active',
      value: 'Status',
      width: '6%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'name',
      value: 'Name',
      headerStyle: 'text-start',
      width: '28%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'logs_count',
      value: 'Total Submissions',
      width: '22%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'last_log_status',
      value: 'Last Submission Status',
      width: '22%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'last_log_date',
      value: 'Last Submission Date',
      width: '22%',
      searchable: false,
      sortable: true,
    },
  ];

  function makeRow(stat) {
    return (
      <>
        <td>
          {stat.active === null ? (
            <span>—</span>
          ) : (
            <Form.Check type='switch' disabled checked={stat.active} />
          )}
        </td>
        <td className='fw-semibold text-start'>{stat.name}</td>
        <td>{stat.logs_count}</td>
        <td>
          {stat.last_log_status === null ? (
            <span>—</span>
          ) : stat.last_log_status ? (
            <i className='bi bi-check-circle-fill text-success' />
          ) : (
            <i className='bi bi-x-circle-fill text-danger' />
          )}
        </td>
        <td>{stat.last_log_date && stat.last_log_date.toLocaleString()}</td>
      </>
    );
  }

  function handleOnClick(asg) {
    navigate(`${asg.id}`);
  }

  return (
    <div className='d-flex flex-column h-100'>
      <DashboardOptions
        courseId={courseId}
        setViewChange={setViewChange}
        currSize={configStats.reduce(
          (acc, curr) => acc + (curr.active === null ? 0 : 1),
          0,
        )}
      />
      <div className='flex-grow-1 bg-light border overflow-auto rounded-3'>
        {configStats && (
          <AdvancedTable
            columns={COLUMNS}
            data={configStats}
            makeRow={makeRow}
            hover={true}
            onClickRow={handleOnClick}
            className='small text-center mb-0'
          />
        )}
      </div>
    </div>
  );
}

export default memo(Grade);
