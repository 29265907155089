import axios from 'axios';
import { BACKEND_URL } from './api.js';

export const saml2_authn_request_url = BACKEND_URL + '/saml2/authn_request';
export const oauth2_response_url = BACKEND_URL + '/oauth2/response';

export function logout() {
  return axios.get(BACKEND_URL + '/logout', { cache: false });
}

export function getName() {
  return axios.get(BACKEND_URL + '/name').then((response) => response?.data);
}
