import axios from 'axios';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { getName } from '../../api/authentication_api';
import { clearCache, createCache } from '../../util/cache';

let first = true;
const exemptions = ['/unsubscribe', '/docs'];

function Interceptor({ setName, setPicture, setRole, setId }) {
  const navigate = useNavigate();
  const isExempt = exemptions.some((x) =>
    window.location.pathname.startsWith(x),
  );

  function handleResponse(response) {
    return response;
  }

  function handleError(error) {
    if (error?.response?.status === 401) {
      setName(null);
      setPicture(null);
      setRole(null);
      setId(null);
      clearCache();
      if (isExempt) {
        return error;
      } else {
        navigate('/login');
        return new Promise(() => null);
      }
    } else if (error?.response?.status === 403) {
      navigate('/courses');
      return new Promise(() => null);
    }
    throw error;
  }

  useEffect(() => {
    if (first) {
      first = false;
      axios.defaults.withCredentials = true;
      resetTabId();
      axios.interceptors.response.use(handleResponse, handleError);
      createCache();
    }
    getName()
      .then((data) => {
        if (data?.name) {
          setName(data.name);
          setPicture(data.picture);
          setRole(data.role);
          setId(data.id);
        }
      })
      .catch(() => null);
  }, [setName, setPicture, setRole, setId]);

  return null;
}

export default memo(Interceptor);

export function resetTabId() {
  axios.defaults.headers.common['X-Tab-ID'] = v4();
}
