import { Spinner } from 'react-bootstrap';
import { LANGS } from '../inspect/Constants';

export function InspectGeneral() {
  return (
    <div className='small'>
      <p>
        Using Inspect, you can analyze student submissions for any assignment
        within a course to check for the use of AI tools or plagiarism. Inspect
        supports both code and text, so you can check any pdf, doc, docx, or txt
        file and a large number of programming languages. To use this tool,
        click on the Inspect card in the Course page. This will take you to a
        page with a table with all assignments in the class and two tabs at the
        top for toggling between code and text analysis.
      </p>
    </div>
  );
}

export function InspectDashboard() {
  return (
    <div className='small'>
      <p>
        When you click on Inspect in the course page, you will be brought to a
        page with a table. This table will contain all of the assignments in the
        course that you can inspect.
        <b>
          &nbsp;Please note that by default, code inspection is selected as can
          be seen from the tab highlighted green at the top of the table. You
          will need to change to Text if you want to do a text inspection.&nbsp;
        </b>
        Initially, they will all be empty as there have been no inspections run.
        For both, code and text options, the table will have the same
        attributes.
      </p>
      <ul>
        <li>
          <b>Name: </b>The name of the assignment in the course
        </li>
        <li>
          <b>Status: </b>
          Status of the inspection for the assignment. There are 3 states:
          <ul>
            <li>
              <i className='bi bi-check-circle-fill text-success' /> -
              Inspection completed with no errors
            </li>
            <li>
              <i className='bi bi-x-circle-fill text-danger' /> - Inspection
              failed
            </li>
            <li>
              <Spinner size='sm' variant='info' /> - Inspection is running{' '}
            </li>
          </ul>
          The status of the inspection will be updated every 5 seconds, so you
          will know when it has finished.
        </li>
        <li>
          <b>Ran at: </b>When the inspection was started
        </li>
        <li>
          <b>Highest Match %/Highest AI %: </b>
          For the last inspection, what was the highest match percentage or the
          percentage of text deteced as AI generated. This will be highlighted
          by red, orange, or green depending on the value. The values range from
          0 to 100 and a higher score means a greater degree of academic
          dishonesty.
        </li>
        <li>
          <b>Actions: </b>
          The actions you can take for this assignment. There are three options
          for both.
          <ul>
            <li>
              <u>Inspect Code/Text: </u>Start an inspection
            </li>
            <li>
              <u>Reinspect Code/Text: </u>Restart an inspection
            </li>
            <li>
              <u>Cancel: </u>Stop an inspection while it is running or queued
            </li>
          </ul>
          You can learn more about these in the Run Code and Run Text sections.
        </li>
      </ul>
    </div>
  );
}

export function InspectStatistics() {
  return (
    <div className='small'>
      <p>
        The statistics page allows you to see data about the inspections you
        have ran for the course. These include the distribution of match
        percentages, count of students, highest count of pairs, etc. To view
        these, you can click on the {'Statistics'} button on the Course page
        where you see the Inspect tool.
      </p>
      <p>
        On this page, you will see three components. The first named{' '}
        {'Filter Dashboard'} at the top allows you to filter the data by the
        assignment you want to see and the type of inspection. Everytime you
        change this, the data below will automatically be updated. The second
        under {'Plots & Tables'} allows you to choose the plot you want to see
        from the dropdown. The thirs contains statistics about the Inspect tool
        for this course in general and about the assignment you filter by if you
        have selected one.
      </p>
    </div>
  );
}

export function InspectCode() {
  return (
    <div className='small'>
      <p>
        You can analyze student code and perform pairwise comparisons using the
        code inspection tool on RamDesk. This is a tool integrated with Moss,
        without the need to learn about Moss, download and upload Canvas
        submissions, and parse results. You can simply just start the
        inspection, and this will be done automatically. Ideally, this tool
        should be used once after the deadline to check for plagiarism. To learn
        more about running and results, check the subsections. The code
        inspection tool will also use the assignment description and its
        integrated links to generate six AI submissions. These will be compared
        with student submissions to check for use of AI. Note that only the
        first three links within the assignment description will be parsed, and
        the links must be to course files, or external PDFs or HTMLs.
      </p>
    </div>
  );
}

export function InspectRunCode() {
  return (
    <div className='small'>
      <p>
        In the dashboard, you can click on the Inspect Code or Reinspect Code to
        start the process. Once you click on this, you will see a modal with a
        few options regarding the configuration. You can hover over the blue
        question marks to get a short description, but the full explanations can
        be found below:
      </p>
      <ul>
        <li>
          <b>Programming Languages: </b>
          The language of the submissions. Only the submissions with the
          matching language will be considered for inspection. This means that
          if you select Java for an assignment submitted in Python, the
          inspection will fail as there is nothing to run.
          <i> Selecting c++ will work for both c and C++ files. </i>
          List of supported languages: {LANGS.join(', ')}.
        </li>
        <li>
          <b>Max Matches: </b>
          This option sets the maximum number of times a given passage may
          appear before it is ignored. A passage of code that appears in many
          programs is probably legitimate sharing and not the result of
          plagiarism. With N, any passage appearing in more than N programs is
          treated as if it appeared in a base file (i.e., it is never reported).
          With n == 2, only passages that appear in exactly two programs are
          reported. If one expects many very similar solutions (e.g., the short
          first assignments typical of introductory programming courses) then
          using 3 or 4 is a good way to eliminate all but truly unusual matches
          between programs while still being able to detect 3-way or 4-way
          plagiarism. The allowed values are 1 to 100.
        </li>
        <li>
          <b>Max rows: </b>
          This option determines the number of matching files to show in the
          results. These will be the n highest results from the inspection. The
          allowed values are 1 to 300. Very rarely you are going to need more
          than 100.
        </li>
        <li>
          <b>Base File: </b>
          When a base file is supplied, program code that also appears in the
          base file is not counted in matches. A typical base file will include,
          for example, the instructor-supplied code for an assignment. Multiple
          files are allowed, but they must be zipped together as you are only
          allowed to upload one file. You should use a base file if it is
          convenient; base files improve results, but are not usually necessary
          for obtaining useful information.
        </li>
      </ul>
      <p>
        After you configure the inspection, you can click Start to start the
        inspection. This will be queued and run when avaliable. You will see the
        progress under Status in the dashboard, which will be updated every 5
        seconds. After it has completed, you can see the results by clicking on
        the row. See View Code Inspection for more details regarding this.
      </p>
    </div>
  );
}

export function InspectViewCodeInspection() {
  return (
    <div className='small'>
      <p>
        After your inspection has completed, you can click on the row for the
        assignment. You will be redirected to a new page with a dropdown and a
        table. This will initally always be the last inspection you ran. If you
        want to see previous runs, you can use the dropdown to select the date
        and time of the run. Below the dropdown is a section where you can see
        the configuration you set when starting the inspection as well.
      </p>
      <p>
        Below this, you will see either a table or a section with a failure
        message if the inspection did not succeed. If it did succeed, you will
        see a table with 7 columns. The first three describe the submission for
        student A, the next 3 describe student B, and the last column describes
        the lines matched between the 2 submissions. This table is sorted by the
        last column, so the most number of lines matched will be first. However,
        as the lines of submissions could be different, we think the match %
        (how much of the code in this file matched with the other student file
        in the pair) column is a better indicator of plagiarism, which is why it
        is colored, with red indicating a high level of similarity to another
        submission. You can analyze this table to see which students might have
        plagiarized or shared code with one another. If you click on any row,
        you can see the file and the similar code between the two submissions.
        To learn more about this, see the View Code Pairs section.
      </p>
      <p>
        You also have the option to download this table as a CSV using the blue
        button next to the dropdown.
      </p>
    </div>
  );
}

export function InspectViewCodePairs() {
  return (
    <div className='small'>
      <p>
        In the table with pairs of students, you can click on any row to see
        details regarding the file and which sections of code matched between
        the two files. Code blocks that matches are highlighted in yellow and
        are colored in different colors to easily distinguish between the
        different matched block. When you click on a block in either file, the
        other file will where the code matches will be scrolled into view. Using
        this feature, you can check if the code reported was a sign of
        plagiarism.
      </p>
      <p>
        If a student&apos;s code matches with an AI-generated response, a row
        will appear with student &apos;GPT4.&apos; By clicking on the row, you
        can view the similarity between the student and AI responses. This is an
        imperfect tool that is not guaranteed to catch the use of generative AI,
        as most large language models will generate different responses each
        time they are run.
      </p>
      <p>
        You have the option to download this report as an html file with the
        download button on the top of the modal displayed here. This file will
        have the same formatting and style but will not contain some of the
        features like clickable code blocks. However, it is still a good report
        to share with others if needed.
      </p>
    </div>
  );
}

export function InspectText() {
  return (
    <div className='small'>
      <p>
        You can analyze student submissions and check for likely AI generated
        code. This is a tool integrated with AI detection programs and offers an
        easy experience without the need to download and upload Canvas
        submissions and parse results. You can simply just start the inspection,
        and this will be done automatically. Ideally, this tool should be used
        once after the deadline to check for plagiarism. To learn more about
        running and results, check the subsections.
      </p>
      <p>
        <b>
          Please note that this is experimental and does not indicate that a
          student has used some form of AI to generate text. This should be used
          to detect the likely use of AI, but the decision to trust these
          results and take action lies upon you, the instructor.
        </b>
      </p>
    </div>
  );
}

export function InspectRunText() {
  return (
    <div className='small'>
      <p>
        In the dashboard, you can click on the Inspect Text or Reinspect Text to
        start the process. Once you click on this, you will see a modal with a
        confirmation. If you click on Start, the inspection will be queued and
        run when avaliable. You will see the progress under Status in the
        dashboard, which will be updated every 5 seconds. After it has
        completed, you can see the results by clicking on the row. See View Text
        Inspection for more details regarding this.
      </p>
    </div>
  );
}

export function InspectViewTextInspection() {
  return (
    <div className='small'>
      <p>
        After your inspection has completed, you can click on the row for the
        assignment. You will be redirected to a new page with a dropdown and a
        table. This will initally always be the last inspection you ran. If you
        want to see previous runs, you can use the dropdown to select the date
        and time of the run.
      </p>
      <p>
        Below this, you will see either a table or a section with a failure
        message if the inspection did not succeed. If it did succeed, you will
        see a table with 5 columns. Each row represents a student and their
        submission.
      </p>
      <ul>
        <li>
          <b>Student Name: </b>The name of the student
        </li>
        <li>
          <b>File Name: </b>The name of the file submitted by the student
        </li>
        <li>
          <b>Total Words: </b>Count of words in the submission
        </li>
        <li>
          <b>Likely AI Words: </b>The count of words reported to be likely
          generated by AI
        </li>
        <li>
          <b>% AI Generated: </b>Percentage of code likely to be AI generated
        </li>
      </ul>
      <p>
        This table is sorted by the percentage seen in the last column, with the
        first row being the submission having the highest percentage of AI
        generated text.
      </p>
      <p>
        You also have the option to download this table as a CSV using the blue
        button next to the dropdown.
      </p>
    </div>
  );
}

export function InspectViewTextFlagged() {
  return (
    <div className='small'>
      <p>
        In the table with students and files, you can click on any row to see
        details regarding the file and which sections in the text are reported
        to be likely AI generated. These are highlighted in yellow.
        <b>
          {' '}
          Please note that due to parsing and the program used, the formatting
          of the original document is not preserved. This means that the text
          will appear as a singular large block of text. However, the content is
          the same.
        </b>
      </p>
      <p>
        You have the option to download this report as an html file with the
        download button on the top of the modal displayed here. This file will
        have the same formatting and style with some additional metadata.
      </p>
    </div>
  );
}
