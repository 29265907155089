import { Accordion, Table } from 'react-bootstrap';
import * as nc from '../nudge/Constants';
import Mentions from '../reusable/Mentions';
import { copy } from './Docs';

const VIS_ALL_KW = nc.NUDGE_KWS.map((kw) => `${kw.id} - \x1E${kw.id}\x1E`).join(
  '\n',
);

export function NudgeGeneral() {
  return (
    <div className='small'>
      <p>
        Using Nudge, you can define &#39;rules&#39; for assignments (not
        quizzes) for sending reminders/messages to students included in the
        rule. These can be set to target assignments x number of days before the
        due date or late date. Additionally, you have the ability to target only
        the students who have not submitted anything, the students who received
        a grade below a threshold you set, or all. Depending on the choice, you
        can define your custom message in the message boxes. You are also able
        to change the days on which the messages are sent, the hour, the
        students to include, and the assignments to include.
      </p>
      <b>Nudge Use Cases:</b>
      <ul>
        <li>
          <b>Due Date, All Students </b>
          For the assignments selected, the selected students will receive a
          message regardless of if they submitted anything or have a grade above
          the threshold (in other words, all students) on the set days at the
          set hour if those assignments fall within the x number of days before
          the submission and the due date.
        </li>
        <li>
          <b>Due Date, Students without a Submission </b>
          For the assignments selected, the selected students will receive a
          message only if they have not submitted anything on the set days at
          the set hour if those assignments fall within the x number of days
          before the submission and the due date.
        </li>
        <li>
          <b>Due Date, Students with a Grade Less Than Threshold </b>
          For the assignments selected, the selected students will receive a
          message only if they have received a grade less than the set threshold
          on the set days at the set hour if those assignments fall within the x
          number of days before the submission and the due date.
        </li>
        <li>
          <b>Late Date, All Students </b>
          For the assignments selected, the selected students will receive a
          message regardless of if they submitted anything or have a grade above
          the threshold (in other words, all students) on the set days at the
          set hour if those assignments fall within within the due date and the
          late date.
        </li>
        <li>
          <b>Late Date, Students without a Submission </b>
          For the assignments selected, the selected students will receive a
          message only if they have not submitted anything on the set days at
          the set hour if those assignments fall within the due date and the
          late date.
        </li>
        <li>
          <b>Late Date, Students with a Grade Less Than Threshold </b>
          For the assignments selected, the selected students will receive a
          message only if they have received a grade less than the set threshold
          on the set days at the set hour if those assignments fall within the
          due date and the late date.
        </li>
      </ul>
      <p>
        You can create multiple rules for targeting different dates and groups
        of students. One might be for late dates, another might be for due date
        students who have not submitted anything, another might be for a
        specific list of students or assignments. There are many possibilities
        with Nudge and we encourage you to try out different ones.
      </p>
      <b>Emails</b>
      <p>
        The emails will be sent from the ramdesk account, with the email
        &#39;compsci_ramdesk_noreply@colostate.edu&#39;. If a student is
        included in multiple rules or has multiple assignments where they are
        targeted by the rule, they could receive a lot of emails from ramdesk.
        Therefore, configuring the days before submission, the assignments
        included, and the number of rules would be best.
      </p>
    </div>
  );
}

export function NudgeStatistics() {
  return (
    <div className='small'>
      <p>
        Nudge statistics are available for each rule you create. These will show
        you how many emails were sent (time, frequency, count, etc), how many
        students were targeted, and how many students received the email. This
        can be useful for understanding how many students are being targeted and
        how many are actually receiving the emails. To view these, you can click
        on the {'Statistics'} button on the Course page where you see the Nudge
        tool.
      </p>
      <p>
        On this page, you will see three components. The first named{' '}
        {'Filter Dashboard'} at the top allows you to filter the data by the
        rule you want to see. Everytime you change this, the data below will
        automatically be updated. The second under {'Plots & Tables'} allows you
        to choose the plot you want to see from the dropdown. The thirs contains
        statistics about the Nudge tool for this course in general and about the
        rule you filter by if you have selected one.
      </p>
    </div>
  );
}

export function NudgeDashboard() {
  return (
    <div className='small'>
      <p>
        When you click on Nudge in the course page, you will be brought to a
        page with a table. This table will initially be empty as there have been
        no nudges set up, but you can click on the green button to add a new
        rule. After clicking on the button, you will be brought to a page where
        you can ocnfigure the nudge settings, assignments to include, students
        to include, frequency, time, days, etc. In the table, you are also able
        to see some geenral statistics like how many emails have been sent in
        total, the number of students in the rule, and the number of
        assignments. You are able to sort all of these and search the assignment
        by name as well.
      </p>
      <p>
        Above this table are a few button that allow you to do bulk operations
        for ease of use. These include the ability to start, stop, download, and
        delete all graders.
      </p>
    </div>
  );
}

export function NudgeTargetDate() {
  return (
    <div className='small'>
      <p>
        You can select between two options for a single rule. These will affect
        which assignments are applicable for sending messages.
      </p>
      <ul>
        <b>Due Date</b>: If you select due date, the rule will look at the due
        dates of all assignments selected. You will also see a field to enter
        the number of days before the due date when the messages should start
        going out. For example, selecting due date and 7 days means that the
        rule will be in effect 7 days before the due date of any assignment
        selected and send nudges if a student has not submitted anything or is
        below a threshold.
      </ul>
      <ul>
        <b>Late Date</b>: If you select late date, the rule will look at if an
        assignment has passed its due date but is still before the late
        deadline. That means if the late date is 2 days, this will only be in
        effect for two days. If an assignment does not have a late date, it will
        be ignored.
      </ul>
    </div>
  );
}

export function NudgeTargetStudent() {
  return (
    <div className='small'>
      <p>
        This option allows you to choose which students are targeted by this
        rule. If you select all students in the list below, only the students
        applicable in this option will receive the nudges.
      </p>
      <ul>
        <li>
          <b>All Students</b>: This options means send a nudge to all students
          regardless of whether they have submitted or what their grade is. Note
          that this only applies to the students selected in the list below, not
          everyone in the course. For example, you might use this to send a
          daily reminder to all students 7 days before an upcoming exam or
          assignment.
        </li>
        <li>
          <b>Students without a Submission</b>: This options means that, from
          the students selected, the ones who have not submitted x number of
          days before the date will receive the nudges.
        </li>
        <li>
          <b>Students with a Grade Less Than Threshold</b>: This means that,
          from the students selected, the ones who did submit but did not
          receive a grade greater than or equal to the threshold set by you will
          receive the nudges. You can set the threshold in the input box after
          you select this option.
        </li>
      </ul>
    </div>
  );
}

export function NudgeStudents() {
  return (
    <div className='small'>
      <p>
        Only the students selected here will be considered by this rule. This
        means that if John did not submit his assignment and the deadline is in
        2 days, he will not receive anything if he is not included in this
        section. You can use the + and - buttons to add and remove all students,
        but a rule must have at least one student associated with it.
      </p>
      <p>
        <b>Advanced Student Selection</b>
        <br />
        If you need more options on selecting students, for example for A/B
        testing or randomly selecting a group of students, you can use the
        advanced student selection feature. This allows you to filter students
        by their grade, group students, and select a fraction of the students
        randomly.
      </p>
      <ul>
        <li>
          Selecting a grade type of score allows you to select the range of
          scores. For example, a (83, 87) would mean get all students in the
          course who have a B in the course. For the most common purpose of
          selecting all students in the class, you can leave it as 0 to 100.
        </li>
        <li>
          Selecting Letter Grade allows you to choose which letter grades to
          include. For example, if A is selected, only the students who have an
          A in the course currently will be retrieved from Canvas. This option
          does not allow you to get all students in the course. For this
          purpose, use score with (0, 100).
        </li>
        <li>
          Group Settings allows you to define how many groups you want to
          create. It is set to 100 by default as this would generate 1 group
          with all the relevant students. You could, for example, have 3 groups
          with 33, 33, and 34% of the students randomly selected from the
          initial list. This has to add up to 100% and any odd students will be
          added to the last group. This is useful for A/B testing if you wanted
          to create 2 groups and send different messages.
        </li>
        <li>
          After clicking on generate, you will see at least two groups. One is
          the original list of students filtered by the grade option you
          selected, and the other is the first group. In case you selected only
          one in the groups settings, this will be the same as the first one.
          However, if you have multiple groups, you will see multiple groups.
          The first will contain either the grade or the scores next to their
          name, but this will not be there in the other groups. You can use the
          copy feature to copy all of the students in a group and paste it below
          in the section to select students to include in the rule.
        </li>
        <li>
          The groups and the configuration will be cleared if you click on the
          clear button, refresh the page, close the tab, or close the browser.
          However, you can still navigate through the site and the information
          will be saved there. For example, you create these groups while
          configuring Rule 1 and copy students from group 1. You can then go to
          another rule and look at the same list and copy students from another
          group to this rule. In this way, you can create rules that target
          different students, which might be useful for A/B testing.
        </li>
      </ul>
    </div>
  );
}

export function NudgeAssignments() {
  return (
    <div className='small'>
      <p>
        You can select one or multiple assignments to be a part of this rule.
        When the date is valid according to the configuration you set up in the
        above sections, the rule will look at all assignments and all students.
        If there is a match, the student will receive the nudge and the message
        will contain the relevant information for this assignment.
      </p>
      <p>
        Any number of assignments can be selected even if the dates are
        completely different. The rule will simply look at the dates for each
        and only consider the ones relevant to the current date.
      </p>
    </div>
  );
}

export function NudgeMessage() {
  return (
    <div className='small'>
      <p>
        In this section, you can define the message the student will receive.
        The to will always be the colostate.edu email associated with the
        student. You can define the subject and the body and use keywords to
        make the nudges highly customizable and relevant to the student and
        assignment being considered. Look in the table below for more details on
        how keywords work and what each does. Using the eye icon next to the
        help icons, you can also visualize what an email might look like. These
        use dummy data, but they will be correct when the emails are sent.
      </p>
      <h6>
        <b>Help on Keywords</b>
      </h6>
      <p>
        You can use keywords inside your messages to denote that it should be
        substituted for something. These have to be wrapped with {'{{ }}'} and
        are enforced by the form. For example, Hello, {'{{student.name}}'}{' '}
        returns &quot;Hello, Jane Doe&quot;. They are case insensitive. See the
        table below for valid keywords and their purpose.
      </p>
      <Table striped bordered>
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Keyword</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {nc.NUDGE_KWS.map((kw) => (
            <tr key={kw.display}>
              <td>{kw.id}</td>
              <td>{kw.desc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h6>
        <b>Visualize all Keywords</b>
        <i
          className='ms-2 bi bi-copy pointer'
          onClick={() => copy(VIS_ALL_KW)}
        />
      </h6>
      <Mentions value={VIS_ALL_KW} readOnly classes='bg-light my-1' />
    </div>
  );
}

export function NudgeSample() {
  return (
    <div className='small'>
      <p>
        Check out these sample messages for different scenarios that have been
        used previously in different classes. You can copy and paste these
        directly onto the message section and visualize them to see how they
        would look. Be sure to modify them to fit your use case.
      </p>
      <Accordion alwaysOpen>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>Sample Subjects</Accordion.Header>
          <Accordion.Body>
            <label className='m-1'>
              <b>Before Due Date</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_SUBJECT_1)}
              />
            </label>
            <Mentions value={nc.SAMPLE_SUBJECT_1} readOnly singleLine />
            <label className='m-1'>
              <b>Before Late Date</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_SUBJECT_2)}
              />
            </label>
            <Mentions value={nc.SAMPLE_SUBJECT_2} readOnly singleLine />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>Sample Messages to All Students</Accordion.Header>
          <Accordion.Body>
            <label className='m-1'>
              <b>Exam Reminder</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_EXAM)}
              />
            </label>
            <Mentions value={nc.SAMPLE_EXAM} readOnly />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3'>
          <Accordion.Header>
            Sample Messages to All Students without a Submission
          </Accordion.Header>
          <Accordion.Body>
            <label className='m-1'>
              <b>No Submissions Before Deadline</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_UNSUB)}
              />
            </label>
            <Mentions value={nc.SAMPLE_UNSUB} readOnly />
            <br />
            <label className='m-1'>
              <b>No Submission Before Late Deadline</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_LATE_UNSUB)}
              />
            </label>
            <Mentions value={nc.SAMPLE_LATE_UNSUB} readOnly />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>
            Sample Messages to All Students Below Threshold
          </Accordion.Header>
          <Accordion.Body>
            <label className='m-1'>
              <b>Submission Below Threshold Before Deadline</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_BT)}
              />
            </label>
            <Mentions value={nc.SAMPLE_BT} readOnly />
            <br />
            <label className='m-1'>
              <b>Submission Below Threshold Before Late Deadline</b>
              <i
                className='ms-2 bi bi-copy pointer'
                onClick={() => copy(nc.SAMPLE_LATE_BT)}
              />
            </label>
            <Mentions value={nc.SAMPLE_LATE_BT} readOnly />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
